import { Stack } from "@fluentui/react"
// import './home.css'
import '../App.css'
import { HoverButton } from "../components/hoverButton"
import { useLocation, useNavigate } from "react-router-dom"

import styles from './thankYou.module.scss'

export const NotRegistered: React.FunctionComponent = () => {
    const navigate = useNavigate();
    return (
        <>
        <Stack className="flex-container-col full-height">
            <Stack className="flex-items-col border">
            </Stack>
            <Stack className="flex-items-col border black-background full-height">
                <Stack className="center full-height">
                    <a href ={'https://riverlee.com.au/'}>
                        <img src={require("../riverleeLogo.png")} width="35%" alt="Riverlee logo" />
                    </a>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Stack className="flex-container-col">
                        <Stack className="flex-items-col">
                            <Stack style={{width:'100%', color: 'white'}}>
                                <h2>Your email is not registered.</h2>
                            </Stack>
                        </Stack>
                    </Stack>
                    <h3 className={`${styles.pageText } ${styles.pageDescription}`}>Please reach out to your regular Riverlee contact to address.</h3>
                    <br></br>
                    <br></br>
                    <Stack>
                        {HoverButton('Back to Home',() => {navigate('/')})}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
        </>
    )
}