import React from 'react';
import { ThemeProvider } from '@fluentui/react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { NewUsers } from './pages/newUsers';
import { ExistingUsers } from './pages/existingUsers';
import { TestPage } from './pages/testPage';
import { LoadingPage } from './pages/loadingPage';
import { ThankYou } from './pages/thankYou';
import { ErrorPage } from './pages/errorPage';
import { NotRegistered } from './pages/notRegistered';

export const App: React.FunctionComponent = () => {
  return (
    <><ThemeProvider className = 'mainTheme'>
      <div className='App'>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="existing_users" element={<ExistingUsers />} />
              <Route path="new_users" element={<NewUsers />} />
              <Route path="loading" element={<LoadingPage/>}/>
              <Route path="thank_you" element={<ThankYou/>}/>
              <Route path="not_registered" element={<NotRegistered/>}/>
              <Route path="error" element={<ErrorPage/>}/>
              <Route path="test" element={<TestPage />} />
            </Routes>
          </BrowserRouter>
        </div>
    </ThemeProvider>
    </>
  )
};