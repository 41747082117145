import { Stack } from "@fluentui/react";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import './newUsers.css'
import '../App.css'
import { InputBox } from "../components/inputBox";
import { HoverButton } from "../components/hoverButton";

export const NewUsers: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const httpFuncAsync = async () => {
        if (inputErrorCheck()) {
            const returnUrl: string = await httpPostFunc(getValuesFromTextBoxes(), navigate);
            window.location.replace(returnUrl); 
        }
    }
    return (
        <>
        <Stack className="flex-container-col full-height">
            <Stack className="flex-items-col border">
            </Stack>
            <Stack className="flex-items-col border black-background full-height">
                <form>
                    <Stack className="center full-height">
                        <a href ={'https://riverlee.com.au/'}>
                            <img src={require("../riverleeLogo.png")} width="35%" alt="Riverlee logo" />
                        </a>
                        <br></br>
                        <br></br>
                        <h2 className="white-text">Sign Up for Guest Access</h2>
                        {InputBox("First Name","text")}
                        <br></br>
                        {InputBox("Last Name","text")}
                        <br></br>
                        {InputBox("Email Address","email")}
                        <br></br>
                        {InputBox("Company Name","text")}
                        <br></br>
                        <br></br>
                        <Stack>
                            {HoverButton('Cancel',() => {navigate('/')})}
                            <br></br>
                            {HoverButton('Sign Up',httpFuncAsync,'submit')}
                        </Stack>
                    </Stack>
                </form>
            </Stack>
        </Stack>
        </>
    )
}

type infObjType = {Title:string,FirstName:string,LastName:string,Company:string, ReturnUrl: string, TenantId: string};

const errorCheckHelper = (idString: string, isEmail: boolean = false): boolean => {
    const currObj = document.getElementById(idString) as HTMLElement;
    const currInputValue = currObj.getElementsByTagName('input')[0];
    const currClassList = currObj.classList;

    if (currInputValue.value.length === 0) {
        currClassList.add('invalid-input');
        return false;
    } else if (isEmail && currInputValue.validity.typeMismatch) {
        currClassList.add('invalid-input');
        return false
    } else currObj.classList.remove('invalid-input');
    
    return true;
}

const inputErrorCheck = () => {
    const firstNameError = errorCheckHelper('FirstName')
    const lastNameError = errorCheckHelper('LastName')
    const emailError = errorCheckHelper('EmailAddress', true)
    const companyError = errorCheckHelper('CompanyName')
    if( firstNameError && lastNameError && emailError && companyError) return true;
    return false;
}

const getValuesFromTextBoxes = (): infObjType => {
    const firstNameValue = (document.getElementById('FirstName') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const lastNameValue = (document.getElementById('LastName') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const emailValue = (document.getElementById('EmailAddress') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const companyValue = (document.getElementById('CompanyName') as HTMLInputElement).getElementsByTagName('input')[0].value;
    const returnUrlValue = window.location.origin + '/thank_you';
    const TenantIdValue = "44429ae4-f10a-4302-9273-92a4a7477d39";

    const infObj: infObjType = {Title: emailValue, FirstName: firstNameValue, LastName: lastNameValue, Company: companyValue, ReturnUrl: returnUrlValue, TenantId: TenantIdValue};

    return infObj;
}

const httpPostFunc = async (infObj: infObjType, navigate: NavigateFunction) => {
    navigate('../loading')
    const response = await fetch('https://nexon-fusion-dev-functions.azurewebsites.net/api/AddExtrenalUserRegistration?code=B1_gACTYGfHqnDYRdNZsOBFJ4Lw9XIlGixzJ9fY9sLuqAzFunXU0tQ==', {
      method: 'POST',
      body: JSON.stringify(infObj),
      headers: {'Content-Type': 'application/json'} 
    });

    switch(Math.floor(response.status/100)) {
        case 4:
        case 5:
            return '/error'
        default:
            break;
    }

    const requestResponse : any = await response.json();

    return requestResponse.inviteRedirectUrl;
}
