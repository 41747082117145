import { DefaultPalette, DocumentCard, DocumentCardActivity, DocumentCardDetails, DocumentCardImage, DocumentCardTitle, ICheckboxStyles, IDocumentCardStyles, IDropdownOption, ImageFit, IStackItemStyles, IStackProps, IStackStyles, IStackTokens, Stack } from "@fluentui/react";
import React from "react";
import './newUsers.css'
import '../App.css'
import { TestImages } from "@fluentui/example-data";

type dispDataType = {ImageLoc:string,URL:string,Title:string,Description:string};
const mockData: dispDataType[] = [
    {ImageLoc:'loc1',URL:'url1',Title:'title1',Description:'desc1'},
    {ImageLoc:'loc2',URL:'url2',Title:'title2',Description:'desc2'},
    {ImageLoc:'loc3',URL:'url3',Title:'title3',Description:'desc3'},
    {ImageLoc:'loc4',URL:'url4',Title:'title4',Description:'desc4'},
    {ImageLoc:'loc5',URL:'url5',Title:'title5',Description:'desc5'},
    {ImageLoc:'loc6',URL:'url6',Title:'title6',Description:'desc6'},
    {ImageLoc:'loc7',URL:'url7',Title:'title7',Description:'desc7'},
    {ImageLoc:'loc8',URL:'url8',Title:'title8',Description:'desc8'},
]

// export interface GridProps {
type GridProps = {
    numItems: number;
    showBoxShadow: boolean;
    preventOverflow: boolean;
    wrap: boolean;
    wrapperWidth: number;
    disableShrink: boolean;
    columnGap: number;
    rowGap: number;
    paddingLeft: number;
    paddingRight: number;
    paddingTop: number;
    paddingBottom: number;
    horizontalAlignment: IStackProps['horizontalAlign'];
    verticalAlignment: IStackProps['verticalAlign'];
    hideEmptyChildren: boolean;
    emptyChildren: string[];
};

// Alignment options
const horizontalAlignmentOptions: IDropdownOption[] = [
    { key: 'start', text: 'Left' },
    { key: 'center', text: 'Center' },
    { key: 'end', text: 'Right' },
    { key: 'space-around', text: 'Space around' },
    { key: 'space-between', text: 'Space between' },
    { key: 'space-evenly', text: 'Space evenly' },
  ];
  const verticalAlignmentOptions: IDropdownOption[] = [
    { key: 'start', text: 'Top' },
    { key: 'center', text: 'Center' },
    { key: 'end', text: 'Bottom' },
  ];
  
  // Tokens definition
  const sectionStackTokens: IStackTokens = { childrenGap: 10 };
  const configureStackTokens: IStackTokens = { childrenGap: 20 };
  const shadowItemCheckboxStyles: Partial<ICheckboxStyles> = { root: { marginRight: 10 } };
  const wrapItemCheckboxStyles: Partial<ICheckboxStyles> = { root: { marginBottom: 10 } };

// initializeIcons();

const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        background: 'transparent',
        color: DefaultPalette.white,
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        height: 50
    },
};

const nonShrinkStackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        background: DefaultPalette.black,
        color: DefaultPalette.white,
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        width: 500,
        height: 50

    },
};

// Tokens definition
// const outerStackTokens: IStackTokens = { childrenGap: 5 };
// const innerStackTokens: IStackTokens = {
//   childrenGap: 5,
//   padding: 10,
// };

const GridConfigContent = (props: GridProps,data:any) => {
    const {
        numItems,
        showBoxShadow,
        preventOverflow,
        wrap,
        wrapperWidth,
        disableShrink,
        columnGap,
        rowGap,
        paddingLeft,
        paddingRight,
        paddingTop,
        paddingBottom,
        horizontalAlignment,
        verticalAlignment,
        hideEmptyChildren,
        emptyChildren,
    } = props;

    const stackStyles: IStackStyles = {
        root: [
            {
                // background: DefaultPalette.themeTertiary,
                background: DefaultPalette.blackTranslucent40,
                marginLeft: 10,
                marginRight: 10,
                minHeight: 100,
                width: `calc(${wrapperWidth}% - 20px)`,
            },
            preventOverflow && {
                overflow: 'hidden' as 'hidden',
            },
        ],
        inner: {
          overflow: preventOverflow ? 'hidden' : ('visible' as 'hidden' | 'visible'),
        },
    };

    const stackItemStyles: IStackItemStyles = {
        root: {
            alignItems: 'center',
            // background: DefaultPalette.themePrimary,
            // boxShadow: showBoxShadow ? `0px 0px 10px 5px ${DefaultPalette.themeDarker}` : '',
            // background: DefaultPalette.neutralDark,
            background: "#2e8af5",
            boxShadow: showBoxShadow ? `0px 0px 10px 5px ${DefaultPalette.blackTranslucent40}` : '',
            color: DefaultPalette.white,
            display: 'flex',
            height: 200,
            justifyContent: 'center',
            width: 150,
        },
    };

    const exampleStackTokens: IStackTokens = {
        childrenGap: rowGap + ' ' + columnGap,
        padding: `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`,
    };

    // {ImageLoc:'loc1',URL:'url1',Title:'title1',Description:'desc1'},
    // {ImageLoc:'loc2',URL:'url2',Title:'title2',Description:'desc2'},
    // {ImageLoc:'loc3',URL:'url3',Title:'title3',Description:'desc3'},
    // {ImageLoc:'loc4',URL:'url4',Title:'title4',Description:'desc4'},
    console.log(mockData)
    const sortedMockData = mockData.sort((a,b) => a.Title.localeCompare(b.Title)).slice(0,numItems);
    console.log(sortedMockData)

    const cardStyles: IDocumentCardStyles = {
        root: { 
            display: 'inline-grid', 
            width: 320 },
    };

    return (
        <>
        <Stack horizontalAlign="center" verticalAlign="center">
            <Stack
                horizontal
                wrap={wrap}
                horizontalAlign={horizontalAlignment}
                verticalAlign={verticalAlignment}
                styles={stackStyles}
                tokens={exampleStackTokens}
                style = {{color:"white"}}>
                    <h1>Nexon Guest Portal</h1>
            </Stack>
            <Stack
                horizontal
                wrap={wrap}
                disableShrink={disableShrink}
                horizontalAlign={horizontalAlignment}
                verticalAlign={verticalAlignment}
                styles={stackStyles}
                tokens={exampleStackTokens}
                >

                {sortedMockData.map((value: dispDataType, index: number) => {
                    
                    // return (
                    //     <div key={index} style={stackItemStyles.root as React.CSSProperties}>
                    //         {value.Title}
                    //         <br></br>
                    //         {value.ImageLoc}
                    //         <br></br>
                    //         {value.Description}
                    //         <br></br>
                    //         {value.URL}
                    //     </div>
                    // );
                    return (
                        <Stack>
                            <DocumentCard
                                // aria-label={
                                //     'Document Card with image. How to make a good design. ' +
                                //     'Last modified by Annie Lindqvist and 2 others in March 13, 2018.'
                                // }
                                styles={cardStyles}
                                onClickHref={window.location.origin + '/error'}
                            >
                                {/* <DocumentCardImage height={150} imageFit={ImageFit.cover} imageSrc={value.ImageLoc} /> */}
                                <DocumentCardImage height={150} imageFit={ImageFit.cover} imageSrc={TestImages.iconOne} />
                                <DocumentCardDetails>
                                    <DocumentCardTitle title={value.Title} shouldTruncate />
                                </DocumentCardDetails>
                                <DocumentCardActivity activity={value.Description} people={[{name:'testPerson',profileImageSrc: TestImages.personaMale}]} />
                            </DocumentCard>
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
        </>
    );
};


export const TestPage: React.FunctionComponent = () => {

    const propsStruct: GridProps = {
        numItems                : mockData.length,
        showBoxShadow           : true,
        preventOverflow         : false,
        wrap                    : true,
        wrapperWidth            : 90,
        disableShrink           : false,
        columnGap               : 30,
        rowGap                  : 30,
        paddingLeft             : 40,
        paddingRight            : 40,
        paddingTop              : 40,
        paddingBottom           : 40,
        // horizontalAlignment     : 'start',
        horizontalAlignment     : 'center',
        verticalAlignment       : 'start',
        hideEmptyChildren       : false,
        emptyChildren           : [],
    }

    return (
        <>
            {GridConfigContent(propsStruct,mockData)}
        </>
    )
}
