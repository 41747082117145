import { ProgressIndicator, Stack } from "@fluentui/react"
import '../App.css'

export const LoadingPage: React.FunctionComponent = () => {
    return (
        <>
        <Stack className="flex-container-col full-height">
            <Stack className="flex-items-col border">
            </Stack>
            <Stack className="flex-items-col border black-background full-height">
                <Stack className="center full-height">
                    <img src={require("../riverleeLogo.png")} width="35%" alt="nexon logo" />
                    <br></br>
                    <br></br>
                    <br></br>
                    <Stack>
                        <ProgressIndicator label="Sit back and relax while we transfer your details . . ." styles={{itemName:{color:'white'} }}/>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
        </>
    )
}