import { Stack } from "@fluentui/react"
// import './home.css'
import '../App.css'
import { HoverButton } from "../components/hoverButton"
import { useLocation, useNavigate } from "react-router-dom"

import styles from './thankYou.module.scss'

export const ThankYou: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const userID = (new URLSearchParams(useLocation().search)).get('id');
    httpProvisionPost(userID);
    return (
        <>
        <Stack className="flex-container-col full-height">
            <Stack className="flex-items-col border">
            </Stack>
            <Stack className="flex-items-col border black-background full-height">
                <Stack className="center full-height">
                    
                    <Stack className="flex-container-col">
                        <Stack className="flex-items-col center">
                            <img src={require("../coffee.png")} width="100%" alt="coffee" />
                        </Stack>
                        <Stack className="flex-items-col">
                            <Stack style={{width:'90%', color: 'white'}}>
                                <h3 className={`${styles.pageQuote}`}>Sit tight and have a cup of coffee</h3>
                            </Stack>
                        </Stack>
                        
                    </Stack>
                    <h3 className={`${styles.pageText } ${styles.pageDescription}`}>We are setting up your access now and will let you know once it has been fully provisioned.</h3>
                    <br></br>
                    <br></br>
                    <Stack>
                        {HoverButton('Back to Home',() => {navigate('/')})}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
        </>
    )
}

const httpProvisionPost = async (userID: string | null) => {
    if (userID !== null) {
        const idData = {id: userID, TenantId: "44429ae4-f10a-4302-9273-92a4a7477d39"}
        const response = await fetch('https://nexon-fusion-dev-functions.azurewebsites.net/api/Finalise_UserProvisioning?', {
        method: 'POST',
        body: JSON.stringify(idData),
        headers: {'Content-Type': 'application/json'} 
        });
        // console.log(response)
        const retResponse = await response.json();
        if (retResponse.url !== '') {
            window.location.replace(retResponse.url); 
        }
        console.log(retResponse)
        // const requestResponse : any = await response.json();
        // console.log(requestResponse);
        // return requestResponse.ReturnURL;
    }
} 