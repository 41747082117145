import { Stack } from "@fluentui/react"
import '../App.css'
import { HoverButton } from "../components/hoverButton"
import { useNavigate } from "react-router-dom"

//TODO: implement specific type error pages
export const ErrorPage: React.FunctionComponent = () => {
    const navigate = useNavigate();
    return (
        <>
        <Stack className="flex-container-col full-height">
            <Stack className="flex-items-col border">
            </Stack>
            <Stack className="flex-items-col border black-background full-height">
                <Stack className="center full-height">
                    <a href ={'https://nexon.com.au/'}>
                        <img src={require("../nexon_logo_white.png")} width="35%" alt="nexon logo" />
                    </a>
                    <br></br>
                    <br></br>
                    <Stack style={{width:'80%', color: 'white'}}>
                        <h3>There has been an error</h3>
                    </Stack>
                    <br></br>
                    <br></br>
                    <Stack>
                        {HoverButton('Back to Home',() => {navigate('/')})}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
        </>
    )
}