import { Stack } from "@fluentui/react"
import './hoverButton.scss'

const buttonElement = (buttonString: string, subFunc: Function, buttonType: any) => {
    return (
        <Stack className="container bottomPad">
            <button type={buttonType} onClick={()=> {subFunc()}} className="surround">
                <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                </span>
                <span className="button-text">{buttonString}</span>
            </button>
        </Stack>
    )
}

export const HoverButton = (buttonString: string, subFunc: Function, type?: string) => {
    switch(type) {
        case 'submit' :
            return (<>{buttonElement(buttonString,subFunc,'submit')}</>)
        default:
            return(<>{buttonElement(buttonString,subFunc,'button')}</>)
    }
}